export const useCart = () => {

    const appConfig = useAppConfig();
    const analytics = useAnalytics();
    const availability = useAvailability();

    const items = useState('cartItems', () => []);
    const presentation = useState('cartPresentation', () => []);
    const dispatchDate = useState('cartDispatchDate');

    const getEmptyTotalPrices = () => {
        return {
            total: {
                value: 0,
                valueWithoutVat: 0,
                formattedValue: 0,
            },
            itemsTotal: {
                valueWithoutVat: 0,
                formattedValue: 0,
            },
            couponSale: {
                valueWithoutVat: 0,
                formattedValue: 0,
            },
            shipping: {
                valueWithoutVat: 0,
                formattedValue: 0,
            },
            payment: {
                valueWithoutVat: 0,
                formattedValue: 0,
            }
        };
    };

    const prices = useState('cartPrices', () => getEmptyTotalPrices());

    const addedToCartDialog = useState('addedToCartDialog', () => { return { show: false }; });

    const totalQuantity = computed(() => items.value.reduce((sum, current) => sum + current.q, 0));
    const isEmpty = computed(() => items.value.length === 0);

    // transforms cart items for GQL usage
    const getInputCartItems = () => {

        let inputCartItems = [];

        // add all parent items
        inputCartItems = inputCartItems.concat(items.value.map(item => {
            return {
                id: item.cId,
                idParent: null,
                idVariant: item.vId,
                isVirtual: !!(item.set && item.set.length),
                count: item.q,
                countForCustomPrice: item.rq,
                services: item.s ? item.s.map(serviceItem => {
                    return {
                        idWareServiceOption: serviceItem.soId,
                        configuration: serviceItem.c?.replaceAll('\n', '%0A'),
                        name: serviceItem.f,
                        text: serviceItem.t
                    }
                }) : null
            };
        }));

        // add all child items in sets
        inputCartItems = inputCartItems.concat(items.value.filter(parentItem => parentItem.set?.length).flatMap(parentItem =>
            parentItem.set?.map(childItem => {
                return {
                    id: `${parentItem.cId}:${childItem.vId}`,
                    idParent: parentItem.cId,
                    idVariant: childItem.vId,
                    isVirtual: false,
                    count: parentItem.q,
                    countForCustomPrice: parentItem.rq,
                    services: childItem.s ? childItem.s.map(serviceItem => {
                        return {
                            idWareServiceOption: serviceItem.soId,
                            configuration: serviceItem.c?.replaceAll('\n', '%0A'),
                            name: serviceItem.f,
                            text: serviceItem.t
                        }
                    }) : null
                };
            })
        ));

        return inputCartItems;
    }

    // fetches additional data from API
    const getPresentation = async (shippingLevelId: number | null | undefined, paymentId: number | null | undefined) => {
        if (items.value.length === 0) {
            prices.value = getEmptyTotalPrices();
            return [];
        }

        const locale = useLocale().getLocale();
        const coupon = useShopCookie().read('coupon');
        const inputCartItems = getInputCartItems();

        const endpointUrl = `/api/cartList?coupon=${encodeURIComponent(coupon ?? '')}&shippingLevelId=${shippingLevelId ?? ''}&paymentId=${paymentId ?? ''}&currencyId=${locale.currencyId}&languageId=${locale.languageId}`;
        const cartListData = (await useApiFetch(endpointUrl, {
            method: 'POST',
            body: {
                items: inputCartItems
            }
        })).data.value.cartList;

        // update prices
        prices.value.total.valueWithoutVat = cartListData.totalPrice.valueWithoutVat;
        prices.value.total.value = cartListData.totalPrice.value;
        prices.value.total.formattedValue = cartListData.totalPrice.formattedValue;

        prices.value.itemsTotal.valueWithoutVat = cartListData.itemsTotalPrice.valueWithoutVat;
        prices.value.itemsTotal.formattedValue = cartListData.itemsTotalPrice.formattedValue;

        prices.value.couponSale.valueWithoutVat = cartListData.couponAbsoluteSalePrice.valueWithoutVat ? cartListData.couponAbsoluteSalePrice.valueWithoutVat : cartListData.couponSalePrice.valueWithoutVat;
        prices.value.couponSale.formattedValue = cartListData.couponAbsoluteSalePrice.valueWithoutVat ? cartListData.couponAbsoluteSalePrice.formattedValue : cartListData.couponSalePrice.formattedValue;

        prices.value.shipping.valueWithoutVat = cartListData.shippingPrice.valueWithoutVat;
        prices.value.shipping.formattedValue = cartListData.shippingPrice.formattedValue;

        prices.value.payment.valueWithoutVat = cartListData.paymentPrice.valueWithoutVat;
        prices.value.payment.formattedValue = cartListData.paymentPrice.formattedValue;

        // reset max dispatch date
        dispatchDate.value = new Date();

        // append fetched data to cartItems (just top level - subitems with services are included in total prices)
        const cartListItemsData = cartListData.items;

        const result = items.value.map(cartItem => {

            const cartListItemData = cartListItemsData.find(q => q.id === cartItem.cId);
            const cartListItemWareData = cartListItemData.variant.ware;

            const itemDispatchDate = getDispatchDateForCartItem(cartListItemData, cartListItemsData);

            if (itemDispatchDate.getTime() > dispatchDate.value.getTime()) {
                dispatchDate.value = itemDispatchDate;
            }

            return {
                cartItemId: cartItem.cId,
                variantId: cartItem.vId,
                quantity: cartItem.q,
                name: cartListItemWareData.name,
                code: cartListItemData.variant.codes.find(q => q.type === appConfig.variantCodeTypeToDisplayInCart)?.code,
                variantName: getNameForCartItem(cartListItemData, cartListItemsData),
                imageId: cartListItemWareData.image?.id,
                serviceInfo: getServiceInfoForCartItem(cartListItemData, cartListItemsData),
                url: cartListItemWareData.page.url,
                unit: cartListItemWareData.unit,
                piecePriceWithoutVat: cartListItemData.itemWithSubitemsPrices.unitPriceWithoutVat,
                piecePriceFormatted: cartListItemData.itemWithSubitemsPrices.formattedUnitPrice,
                totalPriceFormatted: cartListItemData.itemWithSubitemsPrices.formattedTotalPrice,
                customCountPriceFormatted: cartListItemData.itemWithSubitemsPrices.formattedCustomCountPrice,
                linked: cartListItemWareData?.linked.flatMap(linked => linked.ware),
                dispatchText: useUtils().formatDate(itemDispatchDate),
                dispatchDate: itemDispatchDate,
                dataLayerItem: useAnalytics().getItemDetails(cartListItemWareData, cartListItemData.variant),
                dataLayerServiceItem: null
            };
        });

        return result;
    };

    // fetches and updates additional data to the state
    const updatePresentation = async () => presentation.value = await getPresentation();

    const validate = () => {
        // custom cart validation function - remove items not in stock etc.
    }

    const add = async (variantId: Number, quantity: Number, serviceConfiguration: Object, setProducts: Array<Object>, crossSellItems: Array<Object> | undefined) => {

        // set null instead of undefined which will be stripped out of the objects and deepEqual is not working correctly
        serviceConfiguration = serviceConfiguration || null;

        setProducts = setProducts || null;

        let item = items.value.find(q =>
            q.vId === variantId
            && useUtils().isDeepEqual(q.s, serviceConfiguration)
            && useUtils().isDeepEqual(q.set, setProducts));

        if (typeof item !== 'undefined') {
            item.q += quantity;
            item.rq = quantity;
        }
        else {
            item = {
                cId: getIdForNewCartItem(),
                vId: variantId,
                q: quantity,
                s: serviceConfiguration || null,
                set: setProducts || null,
                rq: quantity
            };

            items.value.push(item);
        }

        validate();
        saveCart();

        await updatePresentation();

        analyticsItemEvent('add_to_cart', item.cId, quantity);

        showAddedDialog(item.cId, quantity, crossSellItems);
    };

    const update = async (cartItemId: Number, quantity: Number, absolute: Boolean = true, addedRelativeQuantity: Number | null = null) => {
        let item = items.value.find(q => q.cId === cartItemId);

        if (typeof item !== 'undefined') {

            if (absolute) {

                if (quantity === 0) {
                    analyticsItemEvent('remove_from_cart', item.cId, -item.q);
                }
                else if (item.q > quantity) {
                    analyticsItemEvent('remove_from_cart', item.cId, quantity - item.q);
                }
                else {
                    analyticsItemEvent('add_to_cart', item.cId, quantity - item.q);
                }

                item.q = quantity;
            }
            else {

                if (quantity < 0) {
                    analyticsItemEvent('remove_from_cart', item.cId, quantity);
                }
                else {
                    analyticsItemEvent('add_to_cart', item.cId, quantity);
                }

                item.q += quantity;
                item.rq = addedRelativeQuantity;
            }

            if (item.q < 1) {
                const indexOfItem = items.value.indexOf(item);
                items.value.splice(indexOfItem, 1);
            }

            validate();
            saveCart();

            await updatePresentation();
        }
    };

    const remove = async (cartItemId: Number) => {
        await update(cartItemId, 0, true);
    };

    const clear = async () => {
        if (process.client) {
            useShopCookie().remove('cart');
        }

        items.value = [];
        await updatePresentation();
    }

    const getItems = () => {
        const cookieValue = useShopCookie().read('cart');

        const result = cookieValue || [];

        return result;
    }

    const getIdForNewCartItem = () => {
        const currentMaxId = items.value.length ? Math.max(...items.value.map(item => parseInt(item.cId))) : 0;
        const result = currentMaxId + 1;
        return result.toString();
    }

    const getChildItemsForCartItem = (cartListItem, allCartListItems) => {
        return allCartListItems.filter(q => q.idParent?.split(':')[0] === cartListItem.id);
    }

    const getServicesProcessingDays = (services) => {
        const result = services.reduce((sum, current) => sum + parseInt(current.serviceOption.delivery ?? 0), 0);
        return result;
    }

    const getDispatchDateForCartItem = (cartListItem, allCartListItems): Date | null => {
        const childItems = getChildItemsForCartItem(cartListItem, allCartListItems);

        let result: Date | null = null;

        if (childItems?.length) {
            childItems.forEach(item => {

                const childItemDispatchDate = availability.getVariantDispatchInfo(
                    item.variant.ware.deliveryText,
                    item.variant.deliveryText,
                    item.variant.storedOnOwnStore,
                    item.variant.availableCount,
                    item.variant.deliveryPeriod,
                    item.variant.ware.minNextDelivery,
                    appConfig.deadlineHour,
                    availability.getFreeDays(),
                    getServicesProcessingDays(item.services)
                ).date;

                if (!result || result.getTime() < childItemDispatchDate?.getTime()) {
                    result = childItemDispatchDate;
                }
            });
        }
        else {
            result = availability.getVariantDispatchInfo(
                cartListItem.variant.ware.deliveryText,
                cartListItem.variant.deliveryText,
                cartListItem.variant.storedOnOwnStore,
                cartListItem.variant.availableCount,
                cartListItem.variant.deliveryPeriod,
                cartListItem.variant.ware.minNextDelivery,
                appConfig.deadlineHour,
                availability.getFreeDays(),
                getServicesProcessingDays(cartListItem.services)
            ).date;
        }

        return result;
    }

    const getServicesNamesForCartItem = (services) => {
        return services.map(item => {
            let name = '';

            // special handling for ring size as service instead of variant
            if (item.idServiceOption === useAppConfig().serviceOptionIds.ringSize) {
                name = '/ ' + item.inputName;
            }
            else {
                name += ' + ' + useUtils().capitalize(item.name) + ' (' + (item.serviceOption?.name ?? '') + (!!item.inputName ? (', font ' + item.inputName.split('.')[0]) : '') + ')'
            }

            return name;
        }).join('');
    }

    const getVariantNameWithServices = (cartListItem) => cartListItem.variant.variantName + (cartListItem.services?.length ? ' ' + getServicesNamesForCartItem(cartListItem.services) : '');

    const getNameForCartItem = (cartListItem, allCartListItems) => {
        const childItems = getChildItemsForCartItem(cartListItem, allCartListItems);

        let result;

        if (childItems?.length) {
            result = childItems.map(item => getVariantNameWithServices(item)).join(', ');
        }
        else {
            result = getVariantNameWithServices(cartListItem);
        }

        return result;
    }

    const getServiceInfoForCartItem = (cartListItem, allCartListItems) => {
        const childItems = getChildItemsForCartItem(cartListItem, allCartListItems);

        let result = '';

        if (childItems?.length) {
            result = '<div class="flex flex-wrap gap-4 my-4">' + childItems.filter(item => item.services?.length).map(item => `<img class="rounded-md shadow-around" src="${item.services.find(serviceItem => serviceItem.configuration)?.configuration.substring(2)}" alt="" />`).join('') + '</div>';
        }
        else if (cartListItem.services?.length && cartListItem.services.some(serviceItem => serviceItem.configuration)) {
            result = `<img class="my-4 rounded-md shadow-around" src="${cartListItem.services.find(serviceItem => serviceItem.configuration).configuration.substring(2)}" alt="" />`;
        }

        return result;
    }

    const analyticsItemEvent = async (event, cartItemId, quantity) => {

        // copy item object - quantity will be modified so it won´t affect the original object
        const itemObject = JSON.parse(JSON.stringify(presentation.value.find(q => q.cartItemId === cartItemId)));
        itemObject.dataLayerItem.quantity = quantity;

        const itemEventObject = Object.assign({ value: itemObject.dataLayerItem.quantity * itemObject.piecePriceWithoutVat, items: [itemObject.dataLayerItem] });

        if (event === 'add_to_cart') {
            await useFbAPI().registerServerEvent('ADD_TO_CART', itemEventObject.items);
        }

        analytics.pushEvent(event, { ecommerce: itemEventObject });
        //console.log(itemEventObject);
    }

    const reloadItems = async () => {
        items.value = getItems();
        await updatePresentation();
    }

    const saveCart = () => {
        useShopCookie().set('cart', JSON.stringify(items.value));
    }

    const showAddedDialog = (cartItemId, addedQuantity, crossSellItems) => {
        const dialogData = addedToCartDialog.value;

        dialogData.show = true;
        dialogData.cartItemId = cartItemId;
        dialogData.addedQuantity = addedQuantity;
        dialogData.crossSellItems = crossSellItems;

    }

    return {
        items,
        presentation,
        totalQuantity: readonly(totalQuantity),
        prices: readonly(prices),
        dispatchDate: readonly(dispatchDate),
        isEmpty: readonly(isEmpty),
        add,
        update,
        remove,
        clear,
        getItems,
        getPresentation,
        reloadItems,
        getInputCartItems,
        updatePresentation,
        addedToCartDialog
    }
}